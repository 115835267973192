import React from 'react'
import { GlobalDataContext } from '../../context/context';
import { useContext } from 'react';
import { FaPhoneAlt } from 'react-icons/fa';

function Block_99({ img, text, info }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <section className='w-full py-10 h-auto'>
            <div className="w-full lg:h-[45vh] h-[100vh]  flex lg:flex-row flex-col">
                <div
                    className="lg:w-1/2 w-[90%] rounded-r-full h-full flex items-center justify-center border-white border-t-8 border-b-8 border-r-8 shadow-xl relative "
                    style={{
                        backgroundImage: `url("${img}")`,
                        backgroundPosition: "center",
                        backgroundSize: "cover"
                    }}
                >
                    {/* <img
                        src='https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Insignias%2Feco-%20wh.png?alt=media&token=e0f68613-d181-4d20-863b-9693c9ac5c92'
                        alt='not fount'
                        className="w-[50%] md:w-[30%]"
                    /> */}
                    <div className="w-20 h-20  bottom-[27%] lg:bottom-[33%] -right-8 absolute flex justify-center items-center">
                        <div className="w-16 h-16 rounded-full bg-2  animate-ping transition ease-in-out duration-700"></div>
                        <div className="w-14 h-14 rounded-full bg-2  animate-ping transition ease-in-out duration-100 absolute"></div>
                        <a
                            href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                            className='flex items-center justify-center absolute border border-black w-auto'
                        >
                            <div className="w-[70px] rounded-full h-[70px] border-white border-[3px] bg-2 absolute flex justify-center items-center">
                                <FaPhoneAlt className="text-[20px] text-[#fff]" />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="lg:w-[44%] w-[90%] mx-auto md:mx-0 flex flex-col text-center lg:justify-center justify-center items-center lg:pl-8 pl-0 py-4">
                    <h2 className="lg:text-[36px] text-[36px] font-extrabold pb-3 text-secondary ">{text}</h2>
                    <p>
                        {info}
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Block_99
